.root {
  position: relative;
}

.label {
  position: absolute;
  color: rgba(0, 0, 0, 0.6);
  width: fit-content;
  background-color: white;
  margin-left: 14px;
  font-size: 12px;
}

.border {
  width: 100%;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  border-radius: 5px;
  margin-top: 7px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}

.input {
  padding: 25px 0px 0px 0px;
  width: calc(100% - 25px);
  border: none;
  resize: none;
  outline: none;
  vertical-align: middle;
  font-size: 15px;
  background-color: transparent;
}

.multi_border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 100%;
  border-radius: 5px;
  margin-top: 7px;
  margin-bottom: 10px;
}

.multi_input {
  padding: 10px;
  width: calc(100% - 25px);
  height: calc(100% - 25px);

  font-size: 15px;
  border: none;
  resize: none;
  outline: none;
}
