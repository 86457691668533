.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background-color: whitesmoke; */
}

.contents {
  padding: 40px 40px;
  height: calc(100% - 150px);
  display: flex;
  flex-direction: column;
}

.logo {
  width: 200px;
  margin-bottom: 10px;
  object-fit: scale-down;
}

.version {
  font-size: 12px;
  margin-bottom: 15px;
  color: #bebebe;
}

.camera_base {
  width: calc(100vw - 80px);
  height: calc(100vw - 80px);

  /* width: 40vw; */
  margin-left: auto;
  margin-right: auto;
}

.camera_box {
  border: 1px solid #707070;
  width: calc(100vw - 80px);
  height: calc(100vw - 80px);
  /* min-width: 20vw;
  min-height: 20vw; */

  /* width: 40vw;
  height: 40vw; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.camera_icon {
  color: #707070;
}

.load_image {
  position: absolute;

  /* width: 40vw;
  height: 40vw; */
  width: calc(100% - 2px);
  height: calc(100% - 2px);
}

.name_field {
  margin-top: 10px;
}

.comment_field {
  flex: 1;
  min-height: 38px;
}

.comment_length {
  color: #707070;
  margin-top: -8px;
  margin-bottom: 20px;
  font-size: 12px;
  margin-left: auto;
}

.send_button {
}

.send_child {
  position: relative;
  width: calc(100vw - 80px);
  overflow: hidden;
  border-radius: 10px;
}

.progress {
  position: absolute;
  background-color: red;
  width: 20%;
  height: 100%;
  left: 0;
}

.send_contents {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #cfcfcf;
}

.send_label {
  position: relative;
  z-index: 100;
  padding: 10px 0px;
  font-weight: bold;
  width: 100%;
  color: white;
}

.comment_field_area {
  height: 200;
  font-size: 3em;
}
